import {
  orderStatusConstants,
  orderTypeMapping,
  orderTypeConstants,
} from "constants/order";
import dayjs from "dayjs";
import { Label } from "layout/typography/Label";
import { Calendar, CalendarValueType } from "primereact/calendar";
import { Chart } from "primereact/chart";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { IOrder } from "types/Order/Order";

const StyledChart = styled(Chart)`
  canvas {
    width: 100% !important;
  }
`;

export function SalesVolume({ orders }: { orders: IOrder[] }) {
  const [allOrders, setAllOrders] = useState<IOrder[]>(
    orders.filter(
      (order) =>
        !(
          order.status === orderStatusConstants.CANCELED &&
          !order.payments.length
        )
    )
  );
  const startOfMonth = dayjs().startOf("month").toDate();
  const today = dayjs().toDate();

  const [date, setDate] = useState<Date[]>([startOfMonth, today]);
  const [purchasedOrders, setPurchasedOrders] = useState<IOrder[]>(
    orders.filter((order) => order.status === orderStatusConstants.PAID)
  );
  const [canceledOrders, setCanceledOrders] = useState<IOrder[]>(
    orders.filter(
      (order) =>
        order.status === orderStatusConstants.CANCELED && order.payments.length
    )
  );
  const chartOptions = {
    maintainAspectRatio: false,
    fontSize: 16,
    aspectRatio: 1.5,
    maxBarThickness: 50,
    plugins: {
      tooltips: {
        mode: "index",
        intersect: false,
      },
      legend: {
        align: "start",
        labels: {
          font: {
            size: 14,
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
  const [chartData, setChartData] = useState({});

  const bgColors: { [key: string]: string } = {
    ED: "#7C71FD",
    Bundled: "#0ECFA9",
    PatientResponsibility: "#DAA9FD",
    Bariatrics: "#BDB8FE",
    GFE: "#67E0C8",
  };

  useEffect(() => {
    const filteredOrderTypes = Object.values(orderTypeConstants).filter(
      (type) => {
        const allOrdersCount = allOrders.filter(
          (order) => order.orderType === type
        ).length;
        const purchasedOrdersCount = purchasedOrders.filter(
          (order) => order.orderType === type
        ).length;
        const canceledOrdersCount = canceledOrders.filter(
          (order) => order.orderType === type
        ).length;

        // Include the order type only if there is at least one order
        return (
          allOrdersCount > 0 ||
          purchasedOrdersCount > 0 ||
          canceledOrdersCount > 0
        );
      }
    );
    const data = {
      labels: ["All Created Orders", "Purchased Orders", "Cancelled Orders"],
      datasets: Object.values(filteredOrderTypes).map((type) => ({
        type: "bar",
        label: orderTypeMapping[type],
        backgroundColor: bgColors[type],
        data: [
          allOrders.filter((order) => order.orderType === type).length,
          purchasedOrders.filter((order) => order.orderType === type).length,
          canceledOrders.filter((order) => order.orderType === type).length,
        ],
      })),
    };
    setChartData(data);
  }, [orders, allOrders, purchasedOrders, canceledOrders]);

  function handleOnChangeFilter({
    newValue,
  }: {
    newValue: CalendarValueType | string;
  }) {
    setDate(newValue as Date[]);
    const [startDate, endDate] = newValue as Date[];
    const start = dayjs.utc(startDate);
    const end = dayjs.utc(endDate);
    const allOrdersCreated = orders.filter(
      (order) =>
        !(
          order.status === orderStatusConstants.CANCELED &&
          !order.payments.length
        )
    );
    const allOrdersPurchased = orders.filter(
      (order) => order.status === orderStatusConstants.PAID
    );
    const allOrdersCanceled = orders.filter(
      (order) =>
        order.status === orderStatusConstants.CANCELED && order.payments.length
    );
    if (!startDate && !endDate) {
      setAllOrders(allOrdersCreated);
      setPurchasedOrders(allOrdersPurchased);
      setCanceledOrders(allOrdersCanceled);
    }
    if (startDate && endDate) {
      setAllOrders(
        allOrdersCreated.filter((order) => {
          const dateCreated = dayjs.utc(order.createdAt);
          return dateCreated.isBetween(start, end, "day", "[]");
        })
      );
      setPurchasedOrders(
        allOrdersPurchased.filter((order) => {
          const dateCreated = dayjs.utc(order.createdAt);
          return dateCreated.isBetween(start, end, "day", "[]");
        })
      );
      setCanceledOrders(
        allOrdersCanceled.filter((order) => {
          const dateCreated = dayjs.utc(order.createdAt);
          return dateCreated.isBetween(start, end, "day", "[]");
        })
      );
    } else if (startDate && !endDate) {
      setAllOrders(
        allOrdersCreated.filter((order) => {
          const dateCreated = dayjs.utc(order.createdAt);
          return dateCreated.isAfter(start);
        })
      );
      setPurchasedOrders(
        allOrdersPurchased.filter((order) => {
          const dateCreated = dayjs.utc(order.createdAt);
          return dateCreated.isAfter(start);
        })
      );
      setCanceledOrders(
        allOrdersCanceled.filter((order) => {
          const dateCreated = dayjs.utc(order.createdAt);
          return dateCreated.isAfter(start);
        })
      );
    }
  }
  return (
    <div className="flex flex-column gap-2 field col">
      <div className="flex gap-2">
        <div className="flex flex-column gap-2" style={{ minWidth: "250px" }}>
          <Label htmlFor="from">Order Created Date</Label>
          <Calendar
            data-testid="range_calendar"
            selectionMode="range"
            placeholder="Order Created Date"
            value={date}
            showButtonBar
            onChange={(e) => {
              handleOnChangeFilter({
                newValue: e.target.value || [],
              });
            }}
          />
        </div>
      </div>
      <p className="text-lg font-bold my-2 text-center">Sales Volume</p>
      <StyledChart type="bar" data={chartData} options={chartOptions} />
    </div>
  );
}
