import dayjs from "dayjs";
import { parseDate } from "helpers/date";
import { interpolateString } from "helpers/interpolate";
import { parsePrice, centsToDollars } from "helpers/price";
import { disclaimerDefaultValues } from "pages/Customize/helper";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import { SpecialtyListDict, SpecialtyList } from "types/Provider/Provider";

import {
  StyledDiscountBanner,
  StyledContainer,
  StyledServicesList,
  HorizontalLine,
  StyledLinkButton,
  Spacer,
  PriceWrapper,
  Pill,
} from "../styled";
import DisclaimerDialog from "./DisclaimerDialog";
import PaymentPlan from "./PaymentPlan";

export function Home({
  content,
  orderInfo,
  procedures,
  isGFE,
  showClearEstimateDetails,
  showClearEstimateDetailsSection,
  setDisclaimerDialogVisible,
  facilityFee,
  physicianFee,
  clearPrice,
  fullPrice,
  percentageOff,
  totalSaving,
  totalAllowedInsuranceAmount,
  totalInsurancePayInCents,
  totalFlatCopayInCents,
  totalDeductibleInCents,
  totalCoinsuranceInCents,
  expirationDays,
  setShowPaymentSection,
  publicContact,
  disclaimerDialogVisible,
}: any) {
  const parseText = (text: string) => {
    return interpolateString(text, {
      actualSavings: parsePrice(totalSaving),
    });
  };

  return (
    <>
      <StyledDiscountBanner className=" text-center font-semibold border-round-md p-3">
        {parseText(content?.headline)}
      </StyledDiscountBanner>
      <StyledContainer>
        <div className="flex justify-content-between mb-3 mt-1 gap-2">
          <p className="black font-semibold gray uppercase">
            Services Provided
          </p>
          <p className="gray font-medium text-right">
            Visit Date {parseDate(orderInfo.dateOfService)}
          </p>
        </div>
        <StyledServicesList className="flex flex-column border-round-sm mb-3 gap-3">
          {procedures.map((currentProcedure: any) => (
            <div key={currentProcedure.code}>
              <p className=" font-medium mt-0">
                {`${currentProcedure.code} - ${
                  currentProcedure.cptDescription
                }${
                  currentProcedure.providers[0]?.quantity
                    ? `: Quantity ${currentProcedure.providers[0]?.quantity}`
                    : ""
                }`}
              </p>

              {currentProcedure.providers.map(
                (provider: {
                  name: string;
                  specialty: string;
                  quantity: number;
                }) => (
                  <p className=" mt-0">
                    {`${provider.name}, ${
                      SpecialtyListDict[provider.specialty as SpecialtyList]
                    }`}
                  </p>
                )
              )}
            </div>
          ))}
        </StyledServicesList>
        <HorizontalLine />
        {isGFE && (
          <div className="flex mt-2" style={{ justifyContent: "end" }}>
            <div
              className="flex"
              style={{ flexDirection: "column", alignItems: "end" }}
            >
              <Tag
                className="purple"
                style={{
                  background: "white",
                  cursor: "pointer",
                }}
                onClick={() => showClearEstimateDetails()}
              >
                <div className="flex align-items-center gap-2">
                  {showClearEstimateDetailsSection ? (
                    <>
                      <span className="text-xs">Hide Details</span>
                      <i className="pi pi-chevron-up text-xs" />
                    </>
                  ) : (
                    <>
                      <span className="text-xs">Why do I owe this amount?</span>
                      <i className="pi pi-chevron-down text-xs" />
                    </>
                  )}
                </div>
              </Tag>
              {showClearEstimateDetailsSection && (
                <div
                  className="flex mt-2 p-4"
                  style={{
                    flexDirection: "column",
                    border: "1px",
                    borderRadius: "10px",
                    background: "rgba(71, 105, 234, 0.05)",
                  }}
                >
                  <div className="flex gap-3 text-center mb-3">
                    {`After you meet your deductible, you will be responsible for ${orderInfo.gfeQuote?.coinsurancePercent}% of your medical bills (co-insurance) until you reach your annual out-of-pocket maximum. After that, insurance will cover 100% of the bills`}
                  </div>
                  <div
                    className="flex gap-3 mt-2 text-xs"
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>Total insurance allowable</div>
                    <div>
                      {parsePrice(centsToDollars(totalAllowedInsuranceAmount))}
                    </div>
                  </div>
                  <div
                    className="flex gap-3 mt-2 text-xs"
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>Insurance will Pay</div>
                    <div>
                      {parsePrice(centsToDollars(totalInsurancePayInCents))}
                    </div>
                  </div>
                  <div
                    className="flex gap-3 mt-2 text-xs"
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>Flat Copay Amount Applied</div>
                    <div>
                      {parsePrice(centsToDollars(totalFlatCopayInCents))}
                    </div>
                  </div>
                  <div
                    className="flex gap-3 mt-2 text-xs"
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>Deductible Amount Applied</div>
                    <div>
                      {parsePrice(centsToDollars(totalDeductibleInCents))}
                    </div>
                  </div>
                  <div
                    className="flex gap-3 mt-2 text-xs"
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>Co-insurance Amount Applied</div>
                    <div>
                      {parsePrice(centsToDollars(totalCoinsuranceInCents))}
                    </div>
                  </div>
                  <StyledLinkButton
                    className="p-button-text p-button-primary text-xs p-0 mb-0 mt-2"
                    onClick={() => setDisclaimerDialogVisible(true)}
                  >
                    <p className="small-text">
                      {content.disclaimerTitleEnglish ||
                        disclaimerDefaultValues.disclaimerTitleEnglish}
                    </p>
                  </StyledLinkButton>
                  <DisclaimerDialog
                    visible={disclaimerDialogVisible}
                    onClose={() => setDisclaimerDialogVisible(false)}
                    englishTitle={
                      content.disclaimerTitleEnglish ||
                      disclaimerDefaultValues.disclaimerTitleEnglish
                    }
                    englishDescription={
                      content.disclaimerDescriptionEnglish ||
                      disclaimerDefaultValues.disclaimerDescriptionEnglish
                    }
                    spanishTitle={
                      content.disclaimerTitleSpanish ||
                      disclaimerDefaultValues.disclaimerTitleSpanish
                    }
                    spanishDescription={
                      content.disclaimerDescriptionSpanish ||
                      disclaimerDefaultValues.disclaimerDescriptionSpanish
                    }
                  />
                </div>
              )}
            </div>
          </div>
        )}
        <div className="flex mt-3">
          <div className="flex-1">
            <p className="black font-semibold mb-1">Estimated Payment Due</p>
            <p className="gray leading-5 max-w-10">
              {parseText(content?.estimateDescription)}
            </p>
          </div>
          <Spacer />
          <div className="flex-initial flex-column mb-2 w-100">
            {content?.facilityEstimate && (
              <div className="flex flex-column">
                <p className="text-xs gray text-right">
                  {parseText(content?.facilityFeeDescription)}
                </p>
                <p className="text-lg font-bold text-right">
                  {parsePrice(facilityFee)}
                </p>
              </div>
            )}

            {content?.physicianEstimate && (
              <div className="flex flex-column mt-2">
                <p className="text-xs gray text-right">Physician fee</p>
                <p className="text-lg font-bold text-right">
                  {parsePrice(physicianFee)}
                </p>
              </div>
            )}
          </div>
        </div>

        <PriceWrapper className="flex flex-column justify-content-center align-items-center mt-3 mb-3">
          <div className="flex justify-content-between w-full">
            <div className="text-left flex-1">
              <h2 className="text-lg font-semibold mb-2">
                {parseText(content?.clearPriceTitle) || "Pay Now Price"}
              </h2>
              <p className="text-xs gray text-left">
                {parseText(content?.clearPriceDescription)}
              </p>
            </div>
            <Spacer />
            <div className="text-right">
              <div className="flex align-items-end justify-content-end mt-1">
                <p className="text-2xl purple font-bold text-right">
                  {parsePrice(clearPrice)}
                </p>
              </div>
              {(content.discountPercentTotal === undefined ||
                content.discountPercentTotal === true) && (
                <>
                  <p className="text-xs green font-medium">{`${percentageOff}% off`}</p>
                  <p className="text-xs gray">
                    Total price:{" "}
                    <span style={{ textDecoration: "line-through" }}>
                      {parsePrice(fullPrice)}
                    </span>
                  </p>
                </>
              )}
            </div>
          </div>
        </PriceWrapper>

        {content?.discountExpires && (
          <div className="flex justify-content-center">
            <Pill className="mt-1 mb-3 border-round-md font-semibold py-1 px-2 text-center">
              Discount expires on{" "}
              {dayjs
                .utc(orderInfo.dateOfService)
                .add(expirationDays, "day")
                .format("MMMM D, YYYY")}
            </Pill>
          </div>
        )}
        <PaymentPlan orderInfo={orderInfo} clearPrice={clearPrice} />

        {!!orderInfo.account.enablePaymentPlan && (
          <Button
            className="w-full justify-content-center p-button-outlined mb-1"
            onClick={() =>
              setShowPaymentSection({
                paymentPlan: true,
                paymentSection: false,
              })
            }
          >
            <span className="font-bold">Make Initial Payment</span>
          </Button>
        )}
        <Button
          className="w-full justify-content-center mt-1"
          onClick={() =>
            setShowPaymentSection({
              paymentPlan: false,
              paymentSection: true,
            })
          }
        >
          <span className="font-bold">
            {parseText(content?.purchaseButton)}
          </span>
        </Button>
      </StyledContainer>
      <div className="flex justify-content-center gap-4 mt-4">
        {publicContact().isVisible && (
          <div className="text-center gap-1 flex flex-column">
            <p className=" gray font-bold">{publicContact().name}</p>
            <p className=" gray"> {publicContact().role}</p>
            <p className=" gray"> {publicContact().phone}</p>
          </div>
        )}

        <div className="text-center gap-1 flex flex-column">
          <p className=" gray font-bold">Brooke Leblanc</p>
          <p className=" gray">Clear Health Support</p>
          <p className=" gray">888-918-2522</p>
        </div>
      </div>
    </>
  );
}
