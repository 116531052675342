import { orderTypeConstants } from "constants/order";
import useFormValidation from "hooks/useFormValidation";
import { sizer } from "layout/styles/styled/sizer";
import { Label } from "layout/typography/Label";
import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";
import { IAccountResponse } from "types/Account/AccountResponse";

const StyledCard = styled(Card)<{ ordertypefield: string }>`
  margin-bottom: ${sizer(4)};
  display: ${({ ordertypefield }) =>
    ordertypefield === orderTypeConstants.GFE ||
    ordertypefield === orderTypeConstants.PATIENT_RESPONSIBILITY
      ? "block"
      : "none"};
`;

export function PaymentPlan({
  accountData,
}: {
  accountData: IAccountResponse;
}) {
  const { control, watch, setValue, trigger } = useFormContext();
  const { handleOnBlurField } = useFormValidation();

  const orderTypeField = watch("orderType");

  useEffect(() => {
    if (accountData) {
      const { paymentPlanDuration, paymentPlanMinDownPaymentPercent } =
        accountData.data;
      setValue("paymentPlanDuration", paymentPlanDuration);
      setValue(
        "paymentPlanMinDownPaymentPercent",
        paymentPlanMinDownPaymentPercent
      );
    }
  }, [accountData]);

  return (
    <StyledCard
      data-testid="paymentPlan_Card"
      title="Payment Plan"
      ordertypefield={orderTypeField}
    >
      <div className="flex flex-column gap-4 w-100">
        <div className="formgrid grid w-100">
          <div className="field flex flex-column col-12 md:col-3">
            <Label
              htmlFor="paymentPlanDuration"
              data-testid="paymentPlanMonthsLabel"
            >
              Number of months
            </Label>

            <Controller
              name="paymentPlanDuration"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Dropdown
                  id="paymentPlanDuration"
                  data-testid="months_PaymentPlan"
                  value={value}
                  onChange={onChange}
                  options={[3, 6, 9, 12, 18, 24, 36]}
                  placeholder="Select number of months"
                  className="w-full"
                />
              )}
            />
          </div>

          <div className="field flex flex-column col-12 md:col-3">
            <Label
              htmlFor="paymentPlanMinDownPaymentPercent"
              data-testid="paymentPlanMinDownPaymentPercent"
            >
              Minimum first payment percent
            </Label>

            <Controller
              name="paymentPlanMinDownPaymentPercent"
              control={control}
              render={({ field: { onChange, value, onBlur } }) => (
                <InputNumber
                  value={value}
                  suffix="%"
                  onBlur={() =>
                    handleOnBlurField({
                      onBlur,
                      field: "paymentPlanMinDownPaymentPercent",
                      trigger,
                    })
                  }
                  inputId="paymentPlanMinDownPaymentPercent"
                  data-testid="minDownPaymentPercent_PaymentPlan"
                  onValueChange={onChange}
                  min={0}
                  max={100}
                />
              )}
            />
          </div>
        </div>
      </div>
    </StyledCard>
  );
}
