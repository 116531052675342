import { ChipVariants } from "components/Chip";

export const adjustmentTypesConstants = {
  MPDR: "MPDR",
  NCCI: "NCCI",
  BILATERAL: "Bilateral",
  CASE_RATE: "Case Rate",
  ENDOSCOPY: "Endoscopy",
};

export const adjustmentTypesMapping = {
  [adjustmentTypesConstants.MPDR]: "Multiple Procedure Discount Applied",
  [adjustmentTypesConstants.NCCI]: "National Coding Correction Applied",
  [adjustmentTypesConstants.BILATERAL]:
    "Bilateral procedure adjustment Applied",
  [adjustmentTypesConstants.CASE_RATE]: "Case Rate Adjustment Applied",
  [adjustmentTypesConstants.ENDOSCOPY]: "Base Endoscopy Adjustment Applied",
};

export const orderTypeConstants = {
  ED: "ED",
  BUNDLED: "Bundled",
  PATIENT_RESPONSIBILITY: "PatientResponsibility",
  BARIATRICS: "Bariatrics",
  GFE: "GFE",
};

export const orderStatusConstants = {
  PAID: "Paid",
  EXPIRED: "Expired",
  DELETED: "Deleted",
  REFUNDED: "Refunded",
  SENT_TO_PATIENT: "SentToPatient",
  CANCELED: "Canceled",
  DRAFT: "Draft",
};

export const orderStatusMapping = {
  [orderStatusConstants.SENT_TO_PATIENT]: "Sent",
  [orderStatusConstants.DELETED]: "Deleted",
  [orderStatusConstants.EXPIRED]: "Expired",
  [orderStatusConstants.PAID]: "Paid",
  [orderStatusConstants.REFUNDED]: "Refunded",
  [orderStatusConstants.CANCELED]: "Canceled",
  [orderStatusConstants.DRAFT]: "Pending Review",
};

export const orderStatusChipVariantsMapping = {
  [orderStatusConstants.SENT_TO_PATIENT]: ChipVariants.WARNING,
  [orderStatusConstants.DELETED]: ChipVariants.WARNING,
  [orderStatusConstants.EXPIRED]: ChipVariants.EXPIRED,
  [orderStatusConstants.PAID]: ChipVariants.SUCCESS,
  [orderStatusConstants.REFUNDED]: ChipVariants.EXPIRED,
  [orderStatusConstants.CANCELED]: ChipVariants.EXPIRED,
  [orderStatusConstants.DRAFT]: ChipVariants.EXPIRED,
};

export const orderTypeMapping = {
  [orderTypeConstants.ED]: "ER",
  [orderTypeConstants.BARIATRICS]: "Bariatrics",
  [orderTypeConstants.BUNDLED]: "Clear Package",
  [orderTypeConstants.PATIENT_RESPONSIBILITY]: "Patient Responsibility",
  [orderTypeConstants.GFE]: "Clear Estimate Pro",
};

export const ED_ORDER_TYPE_EXPIRATION_PERIOD = 10;
