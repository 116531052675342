import { buildURLforProviderProcedure } from "pages/CreateOrder/utils/helpers";
import {
  IProviderProcedureResponse,
  ProcedureProviderSearchBody,
} from "types/ProviderProcedure";

import { api } from ".";

export interface IProviderProcedureRequest {
  payerNameField?: string;
  cptCodeField?: string;
  cptDescription?: string;
  providerName?: string;
  specialty?: string;
  connectedHospital?: string;
  page?: number;
  pageSize?: number;
}

const providerProcedureQueries = api.injectEndpoints({
  endpoints: (builder) => ({
    getProviderProcedure: builder.query<
      IProviderProcedureResponse,
      IProviderProcedureRequest
    >({
      query: ({
        page,
        pageSize,
        payerNameField,
        cptCodeField,
        cptDescription,
        providerName,
        specialty,
        connectedHospital,
      }) => {
        return buildURLforProviderProcedure({
          baseUrl: "provider_procedures",
          payerNameField,
          cptCodeField,
          cptDescription,
          providerName,
          specialty,
          connectedHospital,
          page,
          pageSize,
        });
      },
    }),
    deleteProviderProcedure: builder.mutation<any, number>({
      query: (id) => ({
        url: `provider_procedures/${id}`,
        method: "DELETE",
      }),
    }),
    deleteAllProviderProcedure: builder.mutation<any, any>({
      query: (filters) => ({
        url: `provider_procedures/`,
        method: "DELETE",
        body: {
          ...filters,
        },
      }),
    }),
    updateProviderProcedurePrice: builder.mutation<
      any,
      { id: number; priceInCents: number; toBeEdited?: boolean }
    >({
      query: ({ id, priceInCents, toBeEdited }) => ({
        url: `provider_procedures/${id}`,
        method: "PUT",
        body: {
          priceInCents,
          toBeEdited,
        },
      }),
    }),
    searchProviderProcedure: builder.mutation<
      { data: IProviderProcedureResponse },
      ProcedureProviderSearchBody
    >({
      query: ({ accountId, cptCode, payerName }) => ({
        url: `provider_procedures/search`,
        method: "POST",
        body: {
          accountId,
          cptCode,
          payerName,
        },
      }),
    }),
  }),
});

export const {
  useGetProviderProcedureQuery,
  useDeleteProviderProcedureMutation,
  useUpdateProviderProcedurePriceMutation,
  useSearchProviderProcedureMutation,
  useDeleteAllProviderProcedureMutation,
} = providerProcedureQueries;
