import { capitalizeFirstLetter } from "helpers/text";
import { IOrder, IOrderCreateRequest } from "types/Order/Order";
import {
  IOrdersResponse,
  ISingleOrderResponse,
  ISingleOrderResumeResponse,
} from "types/Order/OrderResponse";
import { ICreatePaymentRequest } from "types/Payment";

import { publicApi, api } from ".";

const orderQueries = api.injectEndpoints({
  endpoints: (builder) => ({
    getOrders: builder.query<IOrdersResponse, string | undefined>({
      query: (accountId) =>
        `/orders${accountId ? `/account/${accountId}` : ""}`,
    }),
    getOrder: builder.query<ISingleOrderResponse, string | undefined>({
      query: (orderId) => `/orders/${orderId}`,
    }),
    createOrders: builder.mutation<ISingleOrderResponse, IOrderCreateRequest>({
      query: (newOrder) => ({
        url: "orders",
        method: "POST",
        body: newOrder,
      }),
    }),
    cancelSingleOrder: builder.mutation<ISingleOrderResponse, string>({
      query: (orderId: string) => ({
        url: `orders/${orderId}/cancel`,
        method: "PATCH",
        body: {},
      }),
    }),
    updateOrder: builder.mutation<IOrdersResponse, Partial<IOrder>>({
      query: (orderToUpdate) => ({
        method: "PATCH",
        url: `/orders/${orderToUpdate.id}`,
        body: {
          amountInCents: orderToUpdate.amountInCents,
          orderType: orderToUpdate.orderType,
          integrationErrors: orderToUpdate.integrationErrors,
          orderAttemptNumber: 0,
          externalId: orderToUpdate.externalId,
          serviceDetails: orderToUpdate.serviceDetails,
          accountNumber: orderToUpdate.accountNumber,
          dateOfService: orderToUpdate.dateOfService,
          status: orderToUpdate?.status,
          gfeQuoteId: orderToUpdate.gfeQuoteId,
          patientId: orderToUpdate.patientId,
          patient: {
            firstName: orderToUpdate.patient?.firstName,
            lastName: orderToUpdate.patient?.lastName,
            dateOfBirth: orderToUpdate.patient?.dateOfBirth,
            preferredContactMethod:
              orderToUpdate.patient?.preferredContactMethod,
            phoneNumber: orderToUpdate.patient?.phoneNumber,
            updatedAt: new Date(),
            patientBenefits: {
              isSubscriberPatient:
                orderToUpdate.patient?.patientBenefits.isSubscriberPatient,
              memberID: orderToUpdate.patient?.patientBenefits.memberID,
            },
          },
        },
      }),
    }),
    approveDraftOrder: builder.mutation<
      ISingleOrderResponse,
      IOrderCreateRequest
    >({
      query: (orderToUpdate) => ({
        method: "PATCH",
        url: `/orders/approve/${orderToUpdate.orderId}`,
        body: orderToUpdate,
      }),
    }),
  }),
});

const publicOrderQueries = publicApi.injectEndpoints({
  endpoints: (builder) => ({
    createPayment: builder.mutation<any, ICreatePaymentRequest>({
      query: (payment) => ({
        body: {
          amountInCents: payment.amountInCents,
          paymentType: capitalizeFirstLetter(payment.paymentType),
          stripeBalanceTransactionId: payment.stripeBalanceTransactionId,
          userId: payment.userId,
          providerId: payment.providerId,
        },
        method: "POST",
        url: `orders/${payment.orderId}/payment`,
      }),
    }),
    createOrderPaymentIntent: builder.mutation({
      query: ({
        orderId,
        paymentPlan,
      }: {
        orderId: string;
        paymentPlan: boolean;
      }) => ({
        url: `orders/${orderId}/create_payment_intent`,
        method: "POST",
        body: {
          paymentPlan,
        },
      }),
    }),
    getOrderOverview: builder.query<
      ISingleOrderResumeResponse,
      string | undefined
    >({
      query: (orderId) => `/orders/${orderId}/overview`,
    }),
    getPublicOrder: builder.query<ISingleOrderResponse, string>({
      query: (orderId) => `/orders/${orderId}/public`,
    }),
  }),
});

export const {
  useGetOrdersQuery,
  useGetOrderQuery,
  useCreateOrdersMutation,
  useCancelSingleOrderMutation,
  useUpdateOrderMutation,
  useApproveDraftOrderMutation,
} = orderQueries;

export const {
  useCreatePaymentMutation,
  useCreateOrderPaymentIntentMutation,
  useGetOrderOverviewQuery,
  useGetPublicOrderQuery,
} = publicOrderQueries;
