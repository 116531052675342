import { isValidEmail } from "helpers/email";
import { Label } from "layout/typography/Label";
import { handleChangePhoneWithMask } from "pages/CreateOrder/utils/mask";
import { Checkbox } from "primereact/checkbox";
import { Chips } from "primereact/chips";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useGetReconciliationQuery } from "store/queries/reconciliationConfig";
import styled from "styled-components";

import { formValidationConstants } from "../helpers/formValidations";

const Title = styled.h3`
  margin-bottom: 16px;
`;

type PaymentAndRemitFields =
  | "contactName"
  | "reportType"
  | "unitHospitalNumber"
  | "contactPhone"
  | "taxId"
  | "contactEmail"
  | "emailToReceiveReports"
  | "connectAccountId";

export function PaymentAndRemit() {
  const { data: reconciliationConfig } = useGetReconciliationQuery();

  const {
    control,
    trigger,
    formState: { errors },
  } = useFormContext<any>();

  async function handleOnBlurField({
    onBlur,
    field,
  }: {
    onBlur: () => void;
    field: PaymentAndRemitFields;
  }) {
    trigger(field);
    onBlur();
  }

  function getFormErrorMessage(name: string) {
    return (
      errors[name] && (
        <small className="p-error">{errors[name]?.message as string}</small>
      )
    );
  }

  const reportTypeDropdown = useMemo(() => {
    return (
      reconciliationConfig?.data.map((aType: any) => ({
        name: aType.type,
        value: aType.id,
      })) || []
    );
  }, [reconciliationConfig]);
  return (
    <div className="block bg-white border-round p-4">
      <Title>Payment and Remit Details</Title>
      <div className="formgrid grid">
        <div className="field col-4">
          <Label htmlFor="contactName">Contact Name</Label>
          <Controller
            name="contactName"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputText
                className="w-full"
                id="contactName"
                autoComplete="off"
                value={value || ""}
                placeholder="Enter contact name here"
                aria-labelledby="contactName"
                onBlur={() => {
                  handleOnBlurField({ onBlur, field: "contactName" });
                }}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className="field col-4">
          <Label htmlFor="contactPhone">Contact Phone</Label>
          <Controller
            name="contactPhone"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputText
                className="w-full"
                id="contactPhone"
                autoComplete="off"
                value={value || ""}
                placeholder="Enter phone number here"
                aria-labelledby="contactPhone"
                onBlur={() => {
                  handleOnBlurField({ onBlur, field: "contactPhone" });
                }}
                onChange={(e) => {
                  handleChangePhoneWithMask(e, onChange);
                }}
              />
            )}
          />
        </div>
        <div className="field col-4">
          <Label htmlFor="contactEmail">Contact Email</Label>
          <Controller
            name="contactEmail"
            control={control}
            rules={{
              validate: (email) => {
                if (!email) return true;
                if (isValidEmail(email)) {
                  return true;
                }
                return "Should be real email.";
              },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <InputText
                  className="w-full"
                  id="contactEmail"
                  autoComplete="off"
                  value={value || ""}
                  placeholder="Enter email address here"
                  aria-labelledby="contactEmail"
                  onBlur={() => {
                    handleOnBlurField({ onBlur, field: "contactEmail" });
                  }}
                  onChange={onChange}
                />
                {getFormErrorMessage("contactEmail")}
              </>
            )}
          />
        </div>
        <div className="field col-4">
          <Label htmlFor="reportType">Report Type</Label>
          <Controller
            data-testid="reportType"
            name="reportType"
            control={control}
            rules={{
              required:
                formValidationConstants.PAYMENT_AND_REMIT_DETAILS.REPORT_TYPE,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <Dropdown
                  id="reportType"
                  data-testid="reportType"
                  value={value}
                  onChange={onChange}
                  options={reportTypeDropdown}
                  optionLabel="name"
                  placeholder="Select"
                  className="w-full"
                  onBlur={() => {
                    handleOnBlurField({ onBlur, field: "reportType" });
                  }}
                />
                {getFormErrorMessage("reportType")}
              </>
            )}
          />
        </div>
        <div className="field col-4">
          <Label htmlFor="taxId">Tax Id</Label>
          <Controller
            name="taxId"
            control={control}
            rules={{
              required:
                formValidationConstants.PAYMENT_AND_REMIT_DETAILS.TAX_ID,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <InputText
                  className="w-full"
                  id="taxId"
                  autoComplete="off"
                  value={value || ""}
                  placeholder="Enter tax ID here"
                  aria-labelledby="taxId"
                  onBlur={() => {
                    handleOnBlurField({ onBlur, field: "taxId" });
                  }}
                  onChange={onChange}
                />

                {getFormErrorMessage("taxId")}
              </>
            )}
          />
        </div>
        <div className="field col-4">
          <Label htmlFor="emailToReceiveReports">
            Email to receive reports
          </Label>
          <Controller
            name="emailToReceiveReports"
            control={control}
            rules={{
              required:
                formValidationConstants.PAYMENT_AND_REMIT_DETAILS
                  .EMAIL_TO_RECEIVE_REPORTS,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <Chips
                  id="emailToReceiveReports"
                  name="emailToReceiveReports"
                  aria-labelledby="emailToReceiveReports"
                  value={value}
                  onChange={onChange}
                  className="w-full"
                  placeholder="Enter email to receive reports here"
                  onAdd={(event) => {
                    const isValid = isValidEmail(event.value);
                    return isValid;
                  }}
                  onBlur={() => {
                    handleOnBlurField({
                      onBlur,
                      field: "emailToReceiveReports",
                    });
                  }}
                />
                {getFormErrorMessage("emailToReceiveReports")}
              </>
            )}
          />
        </div>
        <div className="field col-4">
          <Label htmlFor="unitHospitalNumber">Unit hospital number</Label>
          <Controller
            name="unitHospitalNumber"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputText
                className="w-full"
                id="unitHospitalNumber"
                autoComplete="off"
                value={value || ""}
                placeholder="Enter unit hospital number here"
                aria-labelledby="unitHospitalNumber"
                onBlur={() => {
                  handleOnBlurField({
                    onBlur,
                    field: "unitHospitalNumber",
                  });
                }}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className="field col-4">
          <Label htmlFor="connectAccountId">Connect Account ID</Label>
          <Controller
            name="connectAccountId"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputText
                className="w-full"
                id="connectAccountId"
                autoComplete="off"
                value={value || ""}
                placeholder="Enter Connect Account ID"
                aria-labelledby="connectAccountId"
                onBlur={() => {
                  handleOnBlurField({
                    onBlur,
                    field: "connectAccountId",
                  });
                }}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className="field-checkbox col-4">
          <Label htmlFor="enablePaymentPlan">Payment plan enabled?</Label>
          <Controller
            name="enablePaymentPlan"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Checkbox className="ml-3" onChange={onChange} checked={value} />
            )}
          />
        </div>
      </div>
    </div>
  );
}
